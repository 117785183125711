import environmentInfo from '../../config'

const API_GATEWAY = environmentInfo.API_GATEWAY

export const authenticateUser = async (email, password) => {
    console.log("Authenticate user called for user : ", email);
    try {
      const url = `${API_GATEWAY}/cca/cognito/signin`;
      const body = JSON.stringify({
        user_name: email,
        password: password,
        source: "cca-web-ui"
      });
  
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      });
  
      if (!response.ok) {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (data && data.accessToken) {
        return { forceToReset: false, data };
      } else {
        return { forceToReset: true };
      }
    } catch (error) {
      console.error("Error occurred in call:", {error});
      throw new Error('Authentication failed');
    }
  };


export const changePassword = async (email, password, newPassword, callback)=> {
  console.log("Authenticate user called for user : ", email);
  try {
    const url = `${API_GATEWAY}/cca/cognito/signin`;
    const body = JSON.stringify({
      user_name: email,
      password: password,
      new_password: newPassword,
      source: "cca-web-ui"
    });

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!response.ok) {
      throw new Error(`Password reset failed`);
    }
    const data = await response.json();
    callback(null, data);
  } catch (error) {
    console.error("Error occurred in call:", {error});
    callback(new Error('Password reset failed'), null);
  }
};